import { AbstractControl, ValidationErrors } from '@angular/forms';


export class AdditionalValidators {

	static readonly urlPattern = '^((http(s?):\\/\\/www\\.|http(s?):\\/\\/|www\\.)'
		+ '(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]'
		+ '|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:[0-9]{2,5})?(((\\/[a-zA-Z0-9-!@#"№;:?.,$%^&*()_+=\\[\\]]*)+)$)?)$'
		+ '|(http(s?):\\/\\/www\\.|http(s?):\\/\\/|www\\.)'
		+ '([a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{2,5})?(((\\/[a-zA-Z0-9-!@#"№;:?.,$%^&*()_+=\\[\\]]*)+)$)?)$';

	static arrayOfIpv4(control: AbstractControl<string[]>): ValidationErrors | null {

		const reg = /(^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\/((\?:[0-9])|(\?:[1-2][0-9])|(\?:3[0-2]))$/;

		if ( !Array.isArray(control.value) ) {
			return { notArray: true };
		}
		return control.value.reduce((result, item) => {
			result = result || !reg.test(item);
			return result;
		}, false) ? { wrongAddress: true } : null;

	}

	static checkArrayThreeNumbers(control: AbstractControl<string>): ValidationErrors | null {
		if ( !control.value ) {
			return null;
		}
		try {
			if ( !/^\[\s*(\d{3})(\s*,\s*\d{3})*\s*\]$/.test(control.value) ) {
				return { array: true, message: 'Value is not a array. Check tip.' };
			}
			return null;
		} catch (e) {
			return { array: true, message: 'Validation error' };
		}
	}

	static checkArray(control: AbstractControl<string>): ValidationErrors | null {
		if ( !control.value ) {
			return null;
		}
		try {
			if ( !/^\[(\s*(?:"[^"]*"|[a-zA-Z_][a-zA-Z0-9_]*|\d+)(\s*,\s*(?:"[^"]*"|[a-zA-Z_][a-zA-Z0-9_]*|\d+))*)?\s*\]$/.test(control.value) ) {
				return { array: true, message: 'Value is not a array. Check tip.' };
			}
			return null;
		} catch (e) {
			return { array: true, message: 'Validation error' };
		}
	}

	static checkJson(control: AbstractControl<string>): ValidationErrors | null {
		if ( !control.value ) {
			return null;
		}
		try {
			const parsedValue = JSON.parse(control.value);
			if (typeof parsedValue !== "object" || Array.isArray(parsedValue)) {
				return { json: true, message: 'Value is not a object' };
			}
			for (const [key, val] of Object.entries(parsedValue)) {
				if (!/^[A-Za-z0-9 -]+$/.test(key) || !/^[A-Za-z0-9 -]+$/.test(val as string)) {
					return { json: true, message: 'Check validation tip' };
				}
			}
			return null;
		} catch (e) {
			return { json: true, message: 'Validation error' };
		}
	}
}

export class PrefixValidators {
	static validatePrefix(control: AbstractControl<string[]>): ValidationErrors | null {
		let err = false;
		if ( !control.value ) {
			return null;
		}

		const ranges = control.value.toString().split(',');
		ranges.forEach((range): void => {
			const [start, end] = range.split('-').map(Number);
			if ( start > end ) {
				err = true;
			}
			if ( PrefixValidators.countDigits(end) > 0
				&& PrefixValidators.countDigits(start) !== PrefixValidators.countDigits(end) ) {
				err = true;
			}
		});
		if ( ranges.length > 1 ) {
			err = PrefixValidators.arrangeToArr(ranges);
		}
		return err ? { customErr: { value: control.value } } : null;


	}

	static countDigits(n: number): number {
		return n ? n.toString().length : 0;
	}

	static arrangeToArr(elements: string[]): boolean {
		const arrangedRanges: number[] = [];
		let finalArr: (number | number[])[] = [];
		for ( const el of elements ) {
			if ( el.split('-').length > 1 ) {
				const [start, end] = el.split('-').map(Number);
				arrangedRanges.push(...PrefixValidators.range(start, end));
			} else {
				if ( el.indexOf('!') < 0 ) {
					arrangedRanges.push(Number(el));
				}
			}
		}
		for ( const el of elements ) {
			if ( el.indexOf('-') < 0 && el.indexOf('!') >= 0 ) {
				const clearNumber = el.substring(1, el.length);
				finalArr = arrangedRanges.filter(rangeNumber => clearNumber.indexOf(rangeNumber.toString()) >= 0);
			}
		}
		if ( finalArr.length === 0 && elements.find(el => el.indexOf('!') >= 0) === undefined ) {
			finalArr.push(arrangedRanges);
		}
		return finalArr.length <= 0;
	}

	static range(start: number, end: number): number[] {
		const len = end - start + 1;
		return Array.from({ length: len }, (_, i) => start + i);
	}
}



