import { Environment } from '@campaign-portal/components-library';

export const environment: Environment & { iconsBaseUlr?: string; showLogo?: boolean; showTitle?: boolean } = {

	production: true,
	version: '2.0.0',

	protocol: '',
	host: '',
	port: '',
	api: 'v1',
	localStorageKey: 'hlr',
	configURL: `./assets/config.json?v=${(new Date()).getTime()}`,

	exchangeFolder: '',

	title: 'HLR Configurator',
	subTitle: '',

	theme: 'blue',
	themeType: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',

	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',

	showLink: true,
	allowRegistration: false,

	systemTZOffset: 0,
	localTime: false,

	iconsBaseUlr: './assets/icons',
	showLogo: false,
	showTitle: false,

	languagesID: ['en', 'es', 'ru', 'cn']
};
