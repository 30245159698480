<form (ngSubmit)="save()" [formGroup]="ruleForm">
	<div [alarisLoading]="rulesService.loading$">
		<div class="edit-panel-header">
			<alaris-icon name="icon-add-contact"></alaris-icon>
			{{ rule.ruleId ? ('rules.edit' | translate) : ('rules.create' | translate) }}
		</div>
		<div [alarisFade]="'vertical'" class="form-group panel-content">
			<alaris-input formControlName="name" [errors]="errors">
				{{ 'rules.name' | translate }}
			</alaris-input>

			<alaris-text-area [expandable]="true" [placeholder]="'gl.textAreaHint' | translate"
							  formControlName="prefixList"
							  maxHeight="none" mode="tag" [errors]="errors">
				{{ 'rules.prefix_list' | translate }}
			</alaris-text-area>

			<alaris-text-area [expandable]="true" [placeholder]="'gl.textAreaHint' | translate"
							  formControlName="senderList"
							  maxHeight="none" mode="tag">
				{{ 'rules.sender_list' | translate }}
			</alaris-text-area>

			<alaris-multi-select2 [alarisLoading]="sourcesService.loading$" [backdrop]="false"
								  [displayWith]="displayChoices.bind(this)"
								  [expandable]="true"
								  [label]="'rules.choice_list' | translate"
								  [optionsCount]="sources.length"
								  [separator]="separator"
								  formControlName="choiceList"
								  mode="tag" [errors]="errors">
				<alaris-select-filter2 [formControl]="sourcesFilter"></alaris-select-filter2>
				<alaris-multi-options2 *alarisVirtualFor="let source of sources; let index"
									   [value]="source.SourceId"
									   [virtualIndex]="index">
					{{ source.Name }}
				</alaris-multi-options2>
			</alaris-multi-select2>

			@if (ruleForm.controls.choiceList.value && ruleForm.controls.choiceList.value.length > 0) {
				<alaris-card (cdkDropListDropped)="drop($event)" cdkDropList class="choices" size="auto">
					@for (choice of ruleForm.controls.choiceList.value; track choice) {
						<alaris-card size="auto" class="choice text-ellipsis" cdkDrag>
							{{ (choice | source)?.Name }}
						</alaris-card>
					}
				</alaris-card>
			}

			<alaris-toggle [formControl]="productType">{{ 'rules.include_products' | translate }}</alaris-toggle>
			@if (productType.value) {
				<alaris-multi-select2 [alarisLoading]="productsService.loading$" [backdrop]="false"
									  [expandable]="true"
									  [label]="'rules.product_list' | translate"
									  [optionsCount]="products.length"
									  [displayWith]="displayProduct.bind(this)"
									  [separator]="separator"
									  formControlName="productList"
									  mode="tag"
									  [errors]="errors">
					<alaris-select-filter2 [formControl]="productsFilter"></alaris-select-filter2>
					<alaris-multi-options2 *alarisVirtualFor="let product of products; let index"
										   [value]="product.product_id"
										   [virtualIndex]="index">
						{{ product.product_caption }}
						({{ product.acc_currency_code }})
						- {{ product.product_direction === 0 ? 'Client' : 'Vendor' }}
					</alaris-multi-options2>
				</alaris-multi-select2>
			}

			<button (click)="reset()" alaris-button bType="secondary" class="reset-button" size="lg" type="button">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'gl.reset' | translate }}
			</button>
		</div>
		<div class="edit-panel-footer">
			<button [disabled]="ruleForm.invalid || (!ruleForm.dirty && !productType.dirty)"
					alaris-button bType="primary"
					size="lg"
					type="submit">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{ 'gl.submit' | translate }}
			</button>
			<button (click)="close()" alaris-button bType="outline" size="lg" type="button">
				{{ 'actions.close' | translate }}
			</button>
		</div>
	</div>
</form>
