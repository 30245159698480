import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisMultiSelectDisplayWithFn,
	CustomValidators,
	EditPanelInputData
} from '@campaign-portal/components-library';
import { Product, Rule, Source } from '@helpers/types/models';
import { RulesService } from '../rules.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { AdditionalValidators, PrefixValidators } from '@helpers/shared/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProductsService } from '@helpers/repo/products.service';
import { SourcesService } from '../../sources/sources.service';
import { PrefixValidators } from '@helpers/shared/validators';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { debounceTime, pairwise } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'app-edit-rule',
	templateUrl: './edit-rule.component.html',
	styleUrl: './edit-rule.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRuleComponent implements OnInit {
	readonly errors: { key: string; value: string }[] = [
		{key: 'mask', value: 'gl.errors.invalid_format'},
		{key: 'required', value: 'gl.errors.required'}];
	readonly rule: Rule = {
		ruleId: null,
		name: '',
		choiceList: [],
		prefixList: [],
		productList: null,
		senderList: null

	};
	readonly ruleForm = new FormGroup({
		ruleId: new FormControl<number | null>(null),
		name: new FormControl('', { nonNullable: true, validators: Validators.required }),
		productList: new FormControl<number[] | null>(null, CustomValidators.requiredArrayOrNull),
		prefixList: new FormControl<string[]>(
			[],
			{
				nonNullable: true,
				validators: [
					Validators.required,
					Validators.pattern('^\\d{1,99}$|^!?\\d{1,99}((,!?\\d{1,99})|(-\\d{1,99}))+$'),
					PrefixValidators.validatePrefix
				]
			}
		),
		senderList: new FormControl<string[] | null>(
			null,
			[Validators.pattern('^(\\w\\,?)+(\\w)+$')]
		),
		choiceList: new FormControl<number[]>(
			[],
			{ nonNullable: true, validators: CustomValidators.requiredArrayOrNull }
		)
	});

	products: Product[] = [];
	readonly productsFilter = new FormControl('');
	readonly productType = new FormControl<boolean>(false);

	sources: Source[] = [];
	readonly sourcesFilter = new FormControl();

	readonly separator = ', ';

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(EditPanelInputData) private inputData: { rule?: Rule },
		public rulesService: RulesService,
		public productsService: ProductsService,
		public sourcesService: SourcesService,
		private editPanel: AlarisEditPanelService,
		private lService: AlarisLanguageService
	) {
		if ( this.inputData.rule ) {
			this.rule = this.inputData.rule;
		}
	}

	ngOnInit(): void {
		this.reset();
		this.productsService.clientProducts$
			.pipe((takeUntilDestroyed(this.destroyRef)))
			.subscribe((list) => {
				this.productsFilter.reset();
				this.products = list;
			});

		this.productsFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(150))
			.subscribe((value) => {
				this.products = this.productsService.clientProducts.filter((product) => {
					return product.product_caption.toLowerCase().includes(value?.toLowerCase() ?? '');
				});
			});

		this.productType.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				if (!value) {
					this.ruleForm.controls.productList.patchValue(null);
				}
			});

		this.ruleForm.controls.choiceList.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				pairwise()
			)
			.subscribe(([previousValue, currentValue]) => {
				if ( currentValue === null ) {
					const lastChoice = this.sourcesService.list.find((source) => {
						return !previousValue.includes(source.SourceId);
					});
					if ( lastChoice ) {
						previousValue.push(lastChoice.SourceId);
					}
					this.ruleForm.controls.choiceList.patchValue(previousValue);
				}
			});

		this.sourcesService.list$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((list) => {
				this.sourcesFilter.reset();
				this.sources = list;
				this.ruleForm.updateValueAndValidity();
			});

		this.sourcesFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.sources = this.sourcesService.list.filter((source) => {
					return source.Name.includes(value ?? '');
				});
			});

	}

	displayProduct: AlarisMultiSelectDisplayWithFn<Id<exist>> = (products: Id<exist>[] | null): string => {
		if ( products ) {
			return products.length === 0
				? ''
				: products.map(product => this.productsService.map.get(product)?.product_caption).join(this.separator);
		}
		return this.lService.translate('gl.all');
	};

	displayChoices: AlarisMultiSelectDisplayWithFn<Id<exist>> = (sources: Id<exist>[] | null): string => {
		if ( sources ) {
			return sources.length === 0
				? ''
				: sources.map(source => this.sourcesService.map.get(source)?.Name).join(this.separator);
		}
		return this.lService.translate('gl.all');
	};

	reset(): void {
		const rule = structuredClone(this.rule);
		this.ruleForm.reset(rule);
		this.productType.reset(true);
		if ( rule.productList ) {
			this.productType.setValue(true);
		} else {
			this.productType.setValue(false);
		}
	}

	save(): void {
		const rule: Rule = {
			ruleId: this.rule.ruleId,
			name: this.ruleForm.controls.name.value,
			productList: !this.productType.value
				? null
				: this.ruleForm.controls.productList.value === null
					? this.productsService.list.map(product => product.product_id)
					: this.ruleForm.controls.productList.value,
			prefixList: this.ruleForm.controls.prefixList.value,
			senderList: this.ruleForm.controls.senderList.value,
			choiceList: this.ruleForm.controls.choiceList.value === null
				? this.sourcesService.list.map(source => source.SourceId)
				: this.ruleForm.controls.choiceList.value
		};
		if ( rule.productList ) {
			// @ts-expect-error: todo fix on backend - productList requires to be string[]
			rule.productList = rule.productList.map(item => item + '');
		}
		this.rulesService.update(rule)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if (
					resp.data.rule_id
				) {
					this.editPanel.close(true);
				}
			});
	}

	close(): void {
		this.editPanel.close(false);
	}

	drop(event: CdkDragDrop<Id<exist>>): void {
		this.ruleForm.controls.choiceList.markAsDirty();
		const choiceList = this.ruleForm.controls.choiceList.value;
		moveItemInArray(choiceList, event.previousIndex, event.currentIndex);
		this.ruleForm.controls.choiceList.setValue(choiceList);
	}
}
